import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { OrganizationProviderPost } from '../models/organization-provider-post';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationPostService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Retrieves a list of posts for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - Optional parameters to filter posts.
   * 
   * @returns {Observable<{ data: OrganizationProviderPost[], meta: any }>}
   */
  getOrganizationPosts(organizationId: string, params: any = {}): Observable<{ data: OrganizationProviderPost[], meta: any }> {
    return this.apiService.get(`public/organizations/${organizationId}/posts`, params);
  }

  /**
   * Retrieves the specified post.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} postId - The ID of the post.
   * @param {object} params - Optional parameters modify the response.
   * 
   * @returns {Observable<{ data: OrganizationProviderPost, meta: any }>}
   */
  getOrganizationPost(organizationId: string, postId: string, params: any = {}): Observable<{ data: OrganizationProviderPost, meta: any }> {
    return this.apiService.get(`public/organizations/${organizationId}/posts/${postId}`, params);
  }

}
